.login.login-4 .login-aside {
    background: linear-gradient(147.04deg, #F5F7F7 0.74%, #F5F7F7 99.61%);
}

.eq-button {
    background-color: #FAC03A;
}

.flex-center-h {
    justify-content: center;
}

.input-h-over {
    height: 58px !important;
}

.pointer {
    cursor: pointer;
}

.sug-container {
    padding-left: 0;
    position: absolute;
    z-index: 10000;
    background-color: white;
}

.sug-item-active {
    background-color: #EBEDF3;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__item--active {
    background: #267dc0;
    color: #fff;
}

.slider {
    overflow-x: auto; 
    display: flex;
}

.slide {
    flex-shrink: '0';
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

.display-inline {
    display: inline-block;
    margin-left: 4px;
    margin-top: 10px;
}

.display-inline-textfield {
    display: inline-block;
    margin-left: 4px;
    width: 68%
}

.display-inline-select {
    display: inline-block;
    margin-left: 4px;
    width: 70%;
}

.dot {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: inline-block;
}

.crew-dot {
    position: absolute;
}

@media only screen and (max-width: 600px) {
    .job-nav-item {
        width: 100%;
    }

    .ranges {
        height: 0 !important;
    }
}

.job-nav-a {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    margin-left: 0 !important;
}

.image-scaled {
    image-rendering:-moz-crisp-edges;          /* Firefox        */
    image-rendering:-o-crisp-edges;            /* Opera          */
    image-rendering:-webkit-optimize-contrast; /* Safari         */
    image-rendering:optimize-contrast;         /* CSS3 Proposed  */
    -ms-interpolation-mode:nearest-neighbor;   /* IE8+           */
}

.table-image {
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;              /* optional, center the image */
}

.step-graphic {
    font-size: 10rem;
    text-align: center;
}

.mainpage-graphic {
    font-size: 14rem;
    text-align: center;
}

.trial-graphic {
    text-align: center;
    margin-top: 50vh;
    /* margin-left: 10vh */ 
}

.ul-override {
    padding-inline-start: 0px;
}

.table-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}

.wizard-steps {
    flex-wrap: wrap;
}

.wizard-label {
    margin-left: 1.2rem !important;
    margin-right: 1.2rem !important;
}

.notification {
    position: relative;
    display: inline-block;
  }
  
 /*  .notification:hover {
    background: red;
  } */
  
  .notification .badge {
    position: absolute;
    top: -8px;
    right: -8px;
    padding: 5px 7px;
    border-radius: 50%;
    background: red;
    color: white;
  }

  .tooltip-inner {
    background-color: #F64E60 !important;
    /*!important is not necessary if you place custom.css at the end of your css calls. For the purpose of this demo, it seems to be required in SO snippet*/
    color: #fff;
  }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #F64E60 !important;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: #F64E60 !important;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: #F64E60 !important;
}
.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: #F64E60 !important;
}

.locate-ticket-ready-to-submit{
    background-color: #B5B5C3 !important;
    color: black;
    text-align: center;
}

.locate-ticket-submitted{
    background-color: #0606ea !important;
    color: white;
    text-align: center;
}

.locate-ticket-approved{
    background-color: #07ef35 !important;
    color: black;
    text-align: center;
}

.locate-ticket-expiring-in-3-days{
    background-color: yellow !important;
    color: black;
    text-align: center;
}

.locate-ticket-expiring-in-1-day{
    background-color: #d68420 !important;
    color: black;
    text-align: center;
}

.locate-ticket-expired{
    background-color: #fc0606 !important;
    color: white;
    text-align: center;
}

  .fc-day-top.fc-today .fc-day-number {
    background-color: rgb(109, 210, 240);
    border-radius: 70%; /* Circle shape */
    padding: 5px; /* Space around the number */
    border: 1px solid #030400; /* Black yellow border */
    color: #000; /* Optional: Text color */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Ensures proper alignment */
  }

  .job-status-bid{
    color: #c028c0;
  }
  .job-status-upcoming{
    color: #7a81ed;
  }
  .job-status-active{
    color: #008000;
  }
  .job-status-on-hold{
    color: #d1c008;
  }
  .job-status-completed{
    color: #808080;
  }

  .job-calendar-item-border {
    border-width: 3px !important;
  }