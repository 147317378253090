.task-status-open {
    background: rgba(210, 180, 140, .3);
    /* background-color: #D2B48C */
        /*!important is not necessary if you place custom.css at the end of your css calls. For the purpose of this demo, it seems to be required in SO snippet*/
}

.task-status-in-progress {
    background: rgba(7, 236, 57, 0.3);
        /*!important is not necessary if you place custom.css at the end of your css calls. For the purpose of this demo, it seems to be required in SO snippet*/
}

.task-status-in-progress-time-exceeded {
    background: rgba(241, 32, 5, 0.3);
    /*!important is not necessary if you place custom.css at the end of your css calls. For the purpose of this demo, it seems to be required in SO snippet*/
  }

.task-status-on-hold {
    background: rgba(236, 224, 3, 0.3);
        /*!important is not necessary if you place custom.css at the end of your css calls. For the purpose of this demo, it seems to be required in SO snippet*/
}

.task-status-completed {
    background: rgba(170, 169, 167, 0.3);
        /*!important is not necessary if you place custom.css at the end of your css calls. For the purpose of this demo, it seems to be required in SO snippet*/
}

